<script lang="ts" setup>
const props = withDefaults(defineProps<NavbarProps>(), {
  searchType: '',
  fixed: true,
  placeholder: true,
  leftArrow: false,
  withSearch: false,
})

enum LocaleMap {
  EN = 'en',
  TW = 'tw',
}

interface NavbarProps {
  fixed?: boolean
  placeholder?: boolean
  leftArrow?: boolean
  withSearch?: boolean
  searchType?: 'product' | 'recipe' | 'hashtag' | string
  clientSearch?: (event: MouseEvent) => void
  clientLeft?: (event: MouseEvent) => void
}

const { setLocale } = useI18n()
const route = useRoute()
const localPath = useLocalePath()
const Lang = ref<LocaleMap>(LocaleMap.TW)
if ((route.name as string)!.includes('___en')) {
  Lang.value = LocaleMap.EN
}
function onClickLeft() {
  if (props.clientLeft) {
    props.clientLeft(event as MouseEvent)
    return
  }
  navigateTo(localPath('/recipe'), { replace: true })
  // router.back()
}

function conversionTool() {
  navigateTo(localPath('/toolManagement/conversionTool'))
}

function checkedLang() {
  Lang.value = Lang.value === LocaleMap.TW ? LocaleMap.EN : LocaleMap.TW
  setLocale(Lang.value)
}

function handleSearch() {
  if (isInsideIframe)
    return

  if (props.clientSearch) {
    props.clientSearch(event as MouseEvent)
    return
  }

  let uri = '/searchManagement/search'
  if (props.searchType) {
    uri += `?type=${props.searchType}`
  }
  navigateTo(localPath(uri))
}
</script>

<template>
  <van-nav-bar safe-area-inset-top :clickable="false" :left-arrow="props.leftArrow" :placeholder="props.placeholder" :fixed="props.fixed" :border="false" @click-left="onClickLeft">
    <template #title>
      <img
        class="logo"
        src="~/assets/image/logo.png"
        alt=""
        @click="navigateTo(localPath('/'))"
      >
    </template>
    <template #right>
      <i v-if="props.withSearch" class="i-bx-search mr-3 text-xl text-white" @click="handleSearch" />
      <svgo-weighing
        filled
        class="mr-3 text-xl"
        @click="conversionTool"
      />
      <div @click="checkedLang">
        <svgo-ft
          v-if="Lang !== LocaleMap.TW"
          filled
          class="text-xl"
        />
        <svgo-en
          v-else
          filled
          class="text-xl"
        />
      </div>
    </template>
  </van-nav-bar>
</template>

<style scoped lang="scss">
.logo {
  width: 4.375rem;
}
.van-icon {
  color: #fff;
}
</style>
